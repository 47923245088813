<template>
	<div class="home">
		<x-header >模板管理</x-header>
		<x-content v-if="loading" style="height:100%;" :contentStyle="{alignItems:'center',justifyContent:'center',height:'100%'}">
			<bounce-loading></bounce-loading>
		</x-content>
		<x-content v-else  :contentStyle="{display:'block',width:'calc(100% - 32px)',padding:'16px'}">
			<div class="search_warp">
				<el-row class="list-control_warp">
					<el-col :span="5">
						<el-input alwaysShowClear clearable v-model="searchText" @keydown.enter.native="search" placeholder="输入模板名称搜索">
							<template slot="suffix">
								<i @click="search" style="cursor: pointer;" class="el-input__icon base-icon-search"></i>
							</template>
						</el-input>
					</el-col>
					<el-col :span="4" style="margin-left:15px;">
						<el-select  filterable  alwaysShowClear clearable v-model="domainId" @change="search" placeholder="请选择学科领域">
							<el-option :value="item.id" :label="item.name" v-for="(item,index) in domainList" :key="item.id"></el-option>
						</el-select>
					</el-col>
						<div style="display: flex; justify-content: flex-end;">
							<el-button size="medium" type="success" @click="openDialog" icon="el-icon-circle-plus-outline" v-if="hasPermission([PERMISSION.TEMPLATE_CLAIM])">
							<span style="display: inline-flex; align-items: center;">
									<span>&nbsp;认领模版</span>
							</span>
              </el-button>
						<el-button size="medium" type="primary" @click="$router.push(`/manager/template/edit`)" icon="base-icon-plus">
							<span style="display: inline-flex; align-items: center;">
									<span>&nbsp;新增模板</span>
							</span>
						</el-button>
					</div>

				</el-row>
			</div>
			<x-card>
				<el-row  :gutter="10">
					<el-empty v-if="datas.length==0" description="暂无数据"></el-empty>
					<el-col :span="6" v-for="(item,key) in datas"  :key="item.id" style="margin-bottom:10px">
								<div class="templateThumbnail" >
									<el-tag v-if="item.domainId" class="domainTag" size="small" type="success">{{domainNameMap[item.domainId]}}</el-tag>
									<h4>{{item.name}}&nbsp;&nbsp;
										<el-tooltip v-if="item.remarks"  effect="dark" :content="item.remarks" placement="bottom">
											<span class="base-icon-info"></span>
										</el-tooltip>
								</h4>
										<p  v-if="!hasPermission([PERMISSION.TEMPLATE_CLAIM])">{{ item.institutionId?InstituteIdNameMap[item.institutionId]+'模版':'公共模版' }}</p>
										<p style="margin-bottom:5px;height:160px">
											<!--   @click="srcList=[item.baseCode]"  :preview-src-list="srcList" -->
											<el-image v-if="item.fileStore" :src="baseShowUrl+item.fileStore"  class="templateImg" />
											<span v-else style="color:#dbdbdb">
												<i style="font-size:80px;font-weight: lighter;" class="el-icon-picture-outline"></i>
												<div>暂无预览图</div>

											</span>
										</p>
										<p class="option">
											<el-button type="primary" size="mini" @click="previewTemplate(item,0)">预览</el-button>
											<el-button type="warning" size="mini" @click="editTemplate(item)">编辑</el-button>
											<el-button type="info" size="mini" @click="deleteTemplate(item)">删除</el-button>
										</p>
									</div>
							</el-col>
						</el-row>
				<div class="page-warp">
					<el-pagination
						@current-change="pageChange"
						background
						layout="total,prev, pager, next"
						unitText="个模板"
						:total="total"
						:page-size="pageSize"
						:current-page.sync="page"
					></el-pagination>
				</div>
			</x-card>
		</x-content>
		<AppDialog ref="appDialog"  width="900px"  originTitle dialogTitle="模板预览"  class="templatePreviewdialog">
      <el-button type="warning" v-model="item" @click="claimTemplate(item)" style="margin-left: 45%" v-if="hasPermission([PERMISSION.TEMPLATE_CLAIM])&&showRlBtn==1">认领</el-button>
			<form-create :rule="preViewRule" :option="preViewOptions"></form-create>
		</AppDialog>
    <app-dialog ref="templateDialog" dialogTitle="模版认领" originTitle staticDialog width="800px"  class="templatePreviewdialog">
					<el-row class="list-control_warp" style="margin-bottom: 20px;">
					<el-col :span="7">
						<el-input alwaysShowClear clearable v-model="searchText1" @keydown.enter.native="search1" placeholder="输入模板名称搜索">
							<template slot="suffix">
								<i @click="search1" style="cursor: pointer;" class="el-input__icon base-icon-search"></i>
							</template>
						</el-input>
					</el-col>
					<el-col :span="6" style="margin-left:15px;">
						<el-select  filterable  alwaysShowClear clearable v-model="domainId1" @change="search1" placeholder="请选择学科领域">
							<el-option :value="item.id" :label="item.name" v-for="(item,index) in domainList" :key="item.id"></el-option>
						</el-select>
					</el-col>
					</el-row>
				<el-row  :gutter="10">
					<el-empty v-if="allDatas.length==0" description="暂无数据"></el-empty>
					<el-col :span="8" v-for="(item,key) in allDatas"  :key="item.id" style="margin-bottom:10px">
								<div class="templateThumbnail">
									<el-tag v-if="item.domainId" class="domainTag" size="small" type="success">{{domainNameMap[item.domainId]}}</el-tag>
									<h4>{{item.name}}&nbsp;&nbsp;
										<el-tooltip v-if="item.remarks"  effect="dark" :content="item.remarks" placement="bottom">
											<span class="base-icon-info"></span>
										</el-tooltip>
								</h4>
										<p style="margin-bottom:5px;height:160px">
											<img  v-if="item.fileStore" :src="baseShowUrl+item.fileStore" class="templateImg"/>
											<span v-else style="color:#dbdbdb">
												<i style="font-size:80px;font-weight: lighter;" class="el-icon-picture-outline"></i>
												<div>暂无预览图</div>
											</span>
										</p>
										<p class="option">
											<el-button type="primary" size="mini" @click="previewTemplate(item,1)">预览</el-button>
            					<el-button type="warning" size="mini" @click="claimTemplate(item)">认领</el-button>
										</p>
									</div>
							</el-col>
						</el-row>
      <div class="page-warp">
        <el-pagination
            @current-change="pageChange1"
            background
            layout="total,prev, pager, next"
            unitText="个模板"
            :total="allTotal"
            :page-size="pageSize1"
            :current-page.sync="page1"
        ></el-pagination>
      </div>
    </app-dialog>
	</div>
</template>

<script>
	import {List,Delete,AllList,ClaimTemplate} from "@/service/formTemplate";
	import {InstituteListData} from "../../service/institute"
	import {sysSettingAllList} from "../../service/sysSetting"

	import formCreate from '@form-create/element-ui'
	import {GroupMixins} from "@/mixins/groupIndex";
	import XUpload from "@/components/base/XUpload";

	export default {
		name: "TemplateManager",
		mixins:[],
		components:{
			XUpload,
			formCreate: formCreate.$form()
		},
		data(){
			return {
				loading:false,
				searchText:'',
				searchText1:'',
				datas:[],
        allDatas:[],
        allTotal:0,
				page:1,
				pageSize:12,
				page1:1,
				pageSize1:12,
				total:0,
				preViewRule:{},
				preViewOptions:{},
        item:{},
				InstituteIdNameMap:{},
				srcList:[''],
				baseShowUrl:'https://file.scholarin.cn/files?fastdfspath=',
				domainList:[],
				sysSettingType:'domain',
				domainId:null,
				domainId1:null,
				domainNameMap:{},
				showRlBtn:1,
			}
		},
		created() {
			this.search();
			this.getInstituteListData();
			this.getDomainList();
		},
		methods:{
      openDialog(){
        this.allList()
        this.$refs.templateDialog.show();
      },
			roleLoadSuccess(){
				if(!this.IS_LEADER && !this.IS_MANAGER){
					this.$router.push('/manager/group');
				}
			},
			search(){
				this.page = 1;
				this.list();
			},
			pageChange(){
				this.list();
			},
			search1(){
				this.page1 = 1;
				this.allList();
			},
      pageChange1(){
        this.allList();
      },
			list(){
				this.loading = true;
				if(!this.domainId){this.domainId=null}
				List(this.searchText,this.domainId,this.page,this.pageSize).then(e => {
					let data = this.APIParse(e);
					this.datas = data?.records || []
					this.total = data?.total || 0
				}).finally(() => {
					this.loading = false;
				})
			},
      allList(){
        this.loading = true;
				if(!this.domainId1){this.domainId1=null}
        AllList(this.searchText1,this.domainId1,this.page1,this.pageSize1).then(e => {
          let data = this.APIParse(e);
          this.allDatas = data?.records || []
          this.allTotal = data?.total || 0
        }).finally(() => {
          this.loading = false;
        })
      },
			getDomainList(){
        sysSettingAllList(this.sysSettingType).then(e => {
          let data = this.APIParse(e);
          this.domainList=data
					data.forEach(item => {
						this.$set(this.domainNameMap,item.id,item.name)
					})
        })
			},
			previewTemplate(item,showRlBtn){
        this.item=item;
				this.preViewRule = formCreate.parseJson(item.rule);
				this.preViewOptions = JSON.parse(item.options);
				this.preViewOptions.submitBtn = false
				// this.preViewOptions.onSubmit=(formData)=>{
				// 	console.log(formData)
				// }
				this.showRlBtn=showRlBtn;
				this.$refs.appDialog.show();
			},
			deleteTemplate(item){
				this.$confirm('此操作将永久删除该模板, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					Delete(item.id).then(e => {
						let data = this.APIParse(e)
						if(data){
							this.$message.success('模板删除成功')
							this.search();
						}else{
							this.$message.error('模板删除失败，请重试')
						}
					}).catch(ex => {
						this.$message.error('模板删除失败，请重试')
					})
				});
			},
			editTemplate(item){
				this.$router.push(`/manager/template/edit?id=${item.id}`)
			},
      claimTemplate(item){
        ClaimTemplate(item).then(e => {
          let data = this.APIParse(e);
          if(data){
            this.$message.success('认领成功')
            this.list();
            this.$refs.appDialog.hide();
          }else{
            this.$message.error('认领失败')
          }
        }).catch(e => {
          this.$message.error(e.message)
        })
      },
			getInstituteListData(){
				 InstituteListData().then(e => {
              let data = this.APIParse(e);
              data.forEach(item => {
              	this.$set(this.InstituteIdNameMap,item.id,item.instituteName)
              })
          })
			}
		}
	}
</script>
<style scoped lang="scss">
	.home{width: 100%;height: 100%;overflow: auto;}
	.content_warp{display: block;}
	.search_warp{width:100%;margin: 16px 0px;}
	.page-warp{width: 100%;text-align: right;margin-top: 8px;}
.templateThumbnail{
	height: 270px;
	background-color: #fff;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  padding: 15px;
	padding-top: 20px;
  border-radius: 5px;
  text-align: center;
  border:1px solid #fff;
	position: relative;
}
.templateThumbnail:hover{
  border:1px solid #8da7ff;
  //transform: scale(1.02);
}
.templateThumbnail p{
  margin-top: 5px;
  margin-bottom: 10px;
}
.templateThumbnail .domainTag{
	position: absolute;
	top: 0px;
	right: 0px;
}
.templateThumbnail h4{
    line-height: 16px;
    height: 32px;
    margin: 5px 0px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
}
.templateThumbnail .templateImg{
	   // border: 1px solid #eee;
			max-height:160px;
			max-width: 100%;
			/**
			height: 200px;
    object-fit: cover;
    width: 100%;*/
}
.templateThumbnail .option{
	position: absolute;
	margin: 0 auto;
	bottom: 10px;
	left: 20%;
}
</style>
<style >
.templatePreviewdialog .AppDialog__inner{
	max-height: 80vm !important;
	overflow-y: scroll;
}
</style>